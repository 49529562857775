import * as constants from './actionTypes'
import { serialize } from '../utility/serialization'

import Axios from '../axios'

export function successUpdatingEnrollment(response) {
  return {
    type: constants.UPDATE_ENROLLMENT,
    course: response.data.course,
  }
}

export function successDeletingEnrollment(response) {
  return {
    type: constants.DELETE_ENROLLMENT,
    course: response.data.course,
  }
}

// THUNKS

export function updateEnrollment(enrollment, coachMessage) {
  return function (dispatch) {
    return requestUpdateEnrollment(enrollment, coachMessage)
      .then((response) => dispatch(successUpdatingEnrollment(response)))
  }
}

export function deleteEnrollment(enrollment, refund, coachMessage, notifyViaEmail) {
  return function (dispatch) {
    return requestDeleteEnrollment(enrollment, refund, coachMessage, notifyViaEmail)
      .then((response) => dispatch(successDeletingEnrollment(response)))
  }
}

// API CALLS

function requestUpdateEnrollment(enrollment, coachMessage = '') {
  const requestUrl = `/api/v1/enrollments/${enrollment.id}`

  return Axios.put(requestUrl, { ...serialize(enrollment), coach_message: coachMessage })
}

function requestDeleteEnrollment(enrollment, refund, coachMessage = '', notifyViaEmail = false) {
  const requestUrl = `/api/v1/enrollments/${enrollment.id}`

  return Axios.delete(requestUrl, { params: { refund, coach_message: coachMessage, send_email: notifyViaEmail } })
}
